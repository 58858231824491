html {
  scroll-behavior: smooth;
}

.App {
  display: flex;
  flex-direction: column;
}

.header {
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: #fff;
}

.headerScroll {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.07);
}

.headerLinks {
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: flex-end;
  width: 75%;
}

.headerLogo {
  font-weight: bold;
  color: #32344c;
  display: flex;
  align-items: center;
}

.headerLogo img {
  margin-right: 8px;
  height: 32px;
  width: 32px;
}

.headerLinks a {
  text-decoration: none;
  color: #9f54aa;
  margin-left: 24px;
}

.headerLinks a:hover {
  text-decoration: underline;
}

body {
  color: #32344c;
  font-family: "Segoe UI", "Varela Round", Verdana, Helvetica, Arial, sans-serif;
  font-size: 16px;
  padding-bottom: 32px;
  padding-top: 68px;
}

.secondary {
  color: #807c8e;
}

.hero {
  position: relative;
}

.heroVideo {
  width: 100%;
}

.heroOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(0, 0, 0, 0.8);
  color: #f3f5ef;
  font-size: 40px;
  font-weight: bold;
}

.heroOverlay .button {
  margin-top: 32px;
  font-weight: normal;
  font-size: 24px;
  padding: 12px 16px;
}

body .button {
  border: 0;
  text-decoration: none;
  color: #fff;
  background-color: #9f54aa;
  padding: 8px 12px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  display: inline-block;
  line-height: 1.3;
  width: 200px;
  text-align: center;
}

.button:hover {
  background-color: #8f4b99;
}

.section {
  margin: 32px 128px 0 128px;
}

.sectionHeader {
  font-size: 24px;
  font-weight: bold;
  margin: 16px 0;
}

.bulletList {
  display: flex;
  margin-top: 16px;
}

.bullet {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
}

.bullet img {
  align-self: center;
  width: 100px;
  height: 100px;
  flex-shrink: 0;
}

.bullet + .bullet {
  margin-left: 32px;
}

.bullet strong {
  display: block;
  margin: 16px 0 8px;
  font-size: 20px;
}

.contactUs {
  margin-top: 32px;
  text-align: center;
}

.howItWorks {
  display: flex;
  max-width: 85%;
}

.howItWorksImage {
  background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0)
    ),
    url("/laredo.jpg");
  background-position: center center;
  width: 500px;
  height: 200px;
  background-size: cover;
  margin-right: 16px;
  flex-shrink: 0;
}

@media only screen and (max-width: 800px) {
  .bulletList {
    flex-direction: column;
  }
  .bullet + .bullet {
    margin-left: 0;
    margin-top: 32px;
  }
  .bullet img {
    align-self: center;
  }
  .howItWorks {
    flex-direction: column;
  }
  .section {
    margin-right: 16px;
    margin-left: 16px;
  }
  .howItWorks {
    max-width: 100%;
  }
  .howItWorksImage {
    width: 100%;
    height: 125px;
    margin-bottom: 8px;
  }
  .headerLinks a {
    display: none;
  }
  .heroOverlay {
    font-size: 24px;
    padding: 16px 16px;
  }
  .heroOverlay .button {
    margin-top: 16px;
    font-size: 16px;
    padding: 8px 12px;
  }
}

.mainPageSearch {
  margin-top: 16px;
  padding: 16px;
  width: 50%;
  background-color: #9f54aa;
  display: flex;
  border-radius: 4px;
  align-items: center;
}

.searchSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
}

.searchSection > div {
  margin-bottom: 8px;
}

.searchActions {
  align-items: center;
  display: flex;
}

.searchActions > div {
  margin-left: 16px;
}

.searchSuggestions {
  display: flex;
  align-items: center;
}

.searchSuggestions * {
  margin: 4px;
}

.searchResult:hover {
  background: #f5f5f5;
}

.searchResults {
  display: flex;
  justify-content: space-between;
}

.leftResults {
  flex-basis: 33%;
}

.mainResults {
  flex-basis: 66%;
}

.resultSection {
  margin-bottom: 16px;
}

.focusedLevel.focusedLevel {
  background-color: #f5f5f5;
  transition: background-color 1000ms linear;
}

.HTSTreeDetails {
  display: flex;
}

.shipmentDetails {
  display: flex;
  flex-direction: column;
}

.shipmentDetails * {
  margin-bottom: 20px;
}

.shipmentDetailsActions {
  display: flex;
  justify-content: flex-end;
}

.documentThreadHeader {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
}

.documentThreadHeader * {
  margin-right: 8px;
}
